import CryptoJS from 'crypto-js';  
  
  // Function to retrieve the secret key from the server
export async function getSecretKeyFromSecretManager() {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/secret`);
        
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const text = await response.text();
        // console.log('Response text:', text);

        const data = JSON.parse(text);
        return data.secret;
    } catch (error) {
        console.error('Failed to fetch the secret key:', error);
        throw error;
    }
}

  export async function encryptValue(value) {
    try {
      const secretKey = await getSecretKeyFromSecretManager();
      // Simulate encryption logic using the secret key
      // console.log(`encrypted-${value}-with-${secretKey}`);
      const encryptedValue = CryptoJS.AES.encrypt(value, secretKey).toString();
      return encryptedValue;
    } catch (error) {
      console.error('Error encrypting value: ', error);
      return 'Error encrypting value';
    }
  }

  export async function decryptValue(encryptedValue) {
    try {
      const secretKey = await getSecretKeyFromSecretManager();
      const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
      const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedValue;
    } catch (error) {
      console.error('Error decrypting value: ', error);
      return 'Error decrypting value';
    }
  }