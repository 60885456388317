import { shortsCollection,  subscriptionsCollection} from "./Firebase";
import { addDoc } from "firebase/firestore";

  

  export async function createShort(sid, downloadURL, thumbnailURL, values) {
    // Perform additional checks
    // Your submit logic here
    const newShort = {
      id: sid,
      title: formData.title,
      paywallStart: formData.paywallStart,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      image: thumbnailURL,
      video: downloadURL,
      uid: props.userProfile.uid,
      stripeAccountId: props.userProfile.stripe_account_id,
      tags: ["test", "testerson"],
      user: props.id,
    };
    const newNoteRef = await addDoc(shortsCollection, newShort);
  };

  export async function createSubscription(stripeAccountId, subscriberEmail) {
    try {
      // Perform additional checks
      // Your submit logic here
      const newSubscription = {
        stripeAccountId: stripeAccountId,
        subscriberEmail: subscriberEmail,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      };
      const newNoteRef = await addDoc(subscriptionsCollection, newSubscription);
      console.log('Subscription added to Firestore successfully', newNoteRef.id);
      return newNoteRef;
    } catch (error) {
      console.error('Error adding subscription to Firestore:', error);
      throw error;
    }
  }