import React, {useState} from "react";
import { Menu, Modal, Button, List } from 'antd';
import { notification } from 'antd';
import { db } from "../utils/Firebase";
import { collection, getDocs, query, where, getFirestore} from "firebase/firestore";

export default function ProfileSettings(props) {
    const [hasConnectedAccount, setHasConnectedAccount] = React.useState(null);
    const [isRestricted, setIsRestricted] = React.useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [subscriptions, setSubscriptions] = React.useState([]);


    const checkConnectedAccount = async () => {
      if (!props.userProfile.email) {
        console.log("User ID is not set, skipping check.");
        return;
      }

      if (!props.userProfile) {
        console.log("userProfile is not set, skipping check.");
        return;
      }


      console.log("props.userProfile.email: ", props.userProfile.email);
      console.log("props userProfile stripe_account_id: ", JSON.stringify(props.userProfile.stripe_account_id));
      console.log("props userProfile: ", JSON.stringify(props.userProfile));


      const stripe_acct_id = props.userProfile.stripe_account_id;
  
      try {
        console.log("Checking connected account for user:", props.userProfile.email, stripe_acct_id);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/check-connected-account`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ accountId: stripe_acct_id }),
        });
  
        const data = await response.json();

        console.log("Request sent to /check-connected-account with creatorId: ", stripe_acct_id);
        console.log("Response from /check-connected-account: ", data);
      

        console.log("Data from check-connected-account: ", data.hasConnectedAccount);
        setHasConnectedAccount(data.hasConnectedAccount);
        setIsRestricted(data.isRestricted);
        return {
          hasConnectedAccount: data.hasConnectedAccount,
          isRestricted: data.isRestricted,
        };
          
      } catch (error) {
        console.error("Error checking connected account: ", error);
      }
    };
    


    
    const showModal = () => {
      setIsModalVisible(true);
    };

    const hideModal = () => {
      setIsModalVisible(false);
    };


    const fetchSubscriptions = async (email) => {
      try {
        const q = query(collection(db, 'subscriptions'), where('subscriberEmail', '==', email));
        const querySnapshot = await getDocs(q);
        const stripeAccountIds = querySnapshot.docs.map((doc) => doc.data().stripeAccountId);
  
        return stripeAccountIds;
        showModal();
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
      }
    };


    

    
    const showCreateAccountNotification = () => {
      notification.warning({
        message: 'Complete Account Setup',
        description: 'Complete account setup and upload a video to view this feature.',
        placement: 'topRight',
      });
    };
  


    const handleClick = async (e) => {
        console.log("Clicked menu item:", e.key);
        if (e.key === "1") {
            props.removeUser();
          }
        if (e.key === "2") {
            // createCustomerPortalSession(props.userProfile.stripe_account_id, props.userProfile.email);
            const stripeAccountIds = await fetchSubscriptions(props.userProfile.email);
            // const list_subscriptoins = ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5"];
            console.log("Subscriptions from firestore: ", stripeAccountIds);
            // setSubscriptions(stripeAccountIdArray);
            
            const email = props.userProfile.email;

            if(!stripeAccountIds.length > 0) {
              return notification.warning({
                message: 'No subscriptions found',
                description: 'You currenlty have no active subscriptions.',
                placement: 'topRight',
              });
            };


            try {
              console.log("Fetching active stripe account IDs for email:", email, "stripeAccountIdArray:", stripeAccountIds);
              const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/stripe/list-all-subscriptions`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ stripeAccountIds, email }),
              });

              const data = await response.json();
              if (data.activeStripeAccountIds) {
                setSubscriptions(data.activeStripeAccountIds);
                showModal();

              } else {
                console.log("Not active subscriptions found. Raise a toast message", data.message);
                notification.warning({
                  message: 'No active subscriptions found',
                  description: 'Subscribe to an account and find it here.',
                  placement: 'topRight',
                });
          
              }
            } catch (error) {
              console.error('Error fetching active stripe account IDs:', error);
            }            
        }
        if (e.key === "4") {
            const checkConnectedAccountResponse = await checkConnectedAccount();
            if(checkConnectedAccountResponse.hasConnectedAccount && !checkConnectedAccountResponse.isRestricted) {
              createStripeDashboardLink(props.userProfile.stripe_account_id);
            } else {
              console.log("User has not connected account or is restricted. Connected Account: ", checkConnectedAccountResponse.hasConnectedAccount, "Restricted Account: ", checkConnectedAccountResponse.isRestricted);
              showCreateAccountNotification();
            }
        }

         if (e.key === "5") {
          window.open("https://forms.gle/WvJtubwg1DHHhXh7A", "_blank"); 
        }
      };

      const createCustomerPortalSession = async (stripeAccountId, email) => {
        console.log("Creating customer portal session for stripe account:", stripeAccountId, "email:", email);
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/stripe/create-customer-portal-session`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ stripeAccountId, email, returnUrl: `${process.env.REACT_APP_BASE_URL}/profile` }),
          });
      
          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error creating customer portal session: ${errorText}`);
          }
      
          const result = await response.json();
          window.location.href = result.url;
        } catch (error) {
          console.error('Error creating customer portal session:', error);
        }
      };


      const createStripeDashboardLink = async (stripeAccountId) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/stripe/create-dashboard-link`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ stripeAccountId }),
          });
      
          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error creating Stripe dashboard link: ${errorText}`);
          }
      
          const result = await response.json();
          window.location.href = result.url;
        } catch (error) {
          console.error('Error creating Stripe dashboard link:', error);
        }
      };    


      const handleModalItemClick = async (stripeAccountId) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/stripe/create-customer-portal-session`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ stripeAccountId, email: props.userProfile.email, returnUrl: `${process.env.REACT_APP_BASE_URL}/profile` }),
          });

          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Error creating customer portal session: ${errorText}`);
          }

          const result = await response.json();
          if (result.url) {
            window.location.href = result.url;
          } else {
            throw new Error('No URL returned from customer portal session creation');
          }
        } catch (error) {
          console.error('Error creating customer portal session:', error);
        }
      };


      const items = [
        {
          type: 'group',
          label: <span className="menu-group-label">General Settings</span>,
          children: [
            { key: '1', label: 'Logout' },
            { key: '2', label: 'Manage Subscriptions' },
            { key: '5', label: 'Feedback/Contact' },
          ],
        },
        {
          type: 'group',
          label: <span className="menu-group-label creator">Creator Settings</span>,
          children: [
            { key: '4', label: 'View My Dashboard' },
            // { key: '3', label: 'View Subscribers' },
          ],
        },
      ];
        

    return (
        <div className="menu-container">
          <div>
            <Menu onClick={handleClick} mode="vertical" selectable={false} items={items} />
          </div>

          {/* <div>{hasConnectedAccount !== null && hasConnectedAccount !== undefined ? hasConnectedAccount.toString() : 'Loading...'}</div> */}
          {/* <div>{JSON.stringify(props.userProfile, null, 2)}</div>
          <div>{JSON.stringify(subscriptions, null, 2)}</div>
          <div>{isModalVisible.toString()}</div>  */}
          
          
          <div>
            <Modal
            title="Manage Subscriptions"
            open={isModalVisible}
            onCancel={hideModal}
            onClose={hideModal}
            footer={null}
            className="custom-modal"
            >

              <List
              dataSource={subscriptions}
              renderItem={(item, index) => (
                <List.Item onClick={() => handleModalItemClick(item)}>
                  <a>Subscription {index + 1}: {item}</a>
                </List.Item>
              )}
              />
            </Modal>
          </div>
        </div>
    );
  }; 