import React from "react";
import { Link } from "react-router-dom";
import { UserOutlined } from '@ant-design/icons';



export default function Header() {
  return (
    <div className="Header">
      <Link to="/" className="header--title">
        <h1>LongerShorts</h1>
        <img className="header--image" src={`${process.env.PUBLIC_URL}/shorts_favicon.png`} alt="Logo" />
      </Link>
      <Link to="/profile" className="header--user">
        {/* <img src={`${process.env.PUBLIC_URL}/user_icon.png`} alt="User Icon" /> */}
        {/* <IconProvider> */}
        <UserOutlined className="header--userIcon" style={{ fontSize: '24px', color: '#fff' }} />
        {/* </IconProvider> */}
          {/* <img src="../public/user_icon.png" /> */}
          {/* <img src="%PUBLIC_URL%/user_icon.png" /> */}
      </Link>
    </div>

  );
}
