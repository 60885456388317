import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import CryptoJS from "crypto-js";
import React from "react";
import { onSnapshot, query, where, addDoc, doc, getDocs } from "firebase/firestore";
import { encryptValue, decryptValue } from '../utils/cryptoUtils';
import { usersCollection } from "../utils/Firebase";
import { nanoid } from "nanoid";
import { Spin } from 'antd';




export default function GetGoogleLogin({ email, onEmailChange, onUserProfileUpdate }) {
  const [loading, setLoading] = React.useState(false); // State for loading status
  

  async function newUser(email, given_name, family_name) {
    const account_id = await createStripeAccount(email, given_name, family_name);
    const newUser = {
        email: email,
        uid: nanoid(),
        given_name: given_name,
        family_name: family_name, 
        stripe_account_id: account_id,
        createdAt: Date.now(),
        updatedAt: Date.now(), 
      };
      console.log("User added to database: " + JSON.stringify(newUser));
      console.log("Stripe accountId: " + account_id);
      const newNoteRef = await addDoc(usersCollection, newUser);
  }

  async function getUserFromDb(email) {
    const q = query(usersCollection, where("email", "==", email));
    const snapshot = await getDocs(q);
  
    if (!snapshot.empty) {
      const userData = snapshot.docs[0].data();

      return userData;
    } else {
      console.log("No documents found.");
      return null;  // or handle this case as needed
    }
  }

  async function getFirebaseUser(user) {
    const email = user.email;
    const given_name = user.given_name;
    const family_name = user.family_name;
    const user_details = await getUserFromDb(email);
    if (user_details === null || user_details.email === undefined) {
      console.log("Creating new user with details: ", email, given_name, family_name);
      await newUser(email, given_name, family_name);
      const new_user_details = await getUserFromDb(email);
      if (new_user_details === null || new_user_details.email === undefined) {
        return 'Error';
      } else {
        return new_user_details;
      }

    } else {
      return user_details; 
    }
  }


  async function createStripeAccount(email, first_name, last_name) {
    try {
      const user = await getUserFromDb(email);
      if (user) {
        console.log('User already exists:', user);
        return user.stripe_account_id;
      }
      
      console.log('Attempting to create stripe account for: ', email);

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-stripe-account`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'express',
          email: email,
          firstName: first_name,
          lastName: last_name,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create Stripe account');
      }
  

      const data = await response.json();
      if (data.accountId) {
        console.log('Stripe account created:', data.accountId);
        // Handle the account creation success (e.g., redirect to onboarding)
        return data.accountId;
      } else {
        console.error('Failed to create account:', data.error);
        return null;
      }
    } catch (error) {
      console.error('Error creating account:', error);
      return null;
    }
  };


  return (
    <div>
      <GoogleLogin
        onSuccess={async (credentialResponse) => {
          setLoading(true);
          //Stock Gmail login code
          var credentialResponseDecoded = jwtDecode(
            credentialResponse.credential,
          );
          console.log("Gmail API response: "+ JSON.stringify(credentialResponseDecoded));

          //Get the user details from our database
          const firebase_user = await getFirebaseUser(credentialResponseDecoded);
          const firebase_user_string = JSON.stringify(firebase_user);
          console.log('Firebase User: ' + firebase_user_string);

          //Encrypt the user details, to store them in our website's local storage securely 
          const encrypted_user = await encryptValue(firebase_user_string);
          console.log('Encrypted response: ' + encrypted_user);

          //Store the encrypted user details in local storage
          localStorage.setItem("userKey", encrypted_user);

          //Update the userProfile and email state variables
          onUserProfileUpdate(firebase_user);
          onEmailChange(firebase_user.email); //May be redundant. 

          setLoading(false);
        }}
        onError={() => {
          console.log("Login Failed");
          onEmailChange("Login Failed");
        }}
        // useOneTap
      />
      <br />
      {loading && (
        <div>
          <Spin tip="Loading..." />
          <div>Loading</div>
          <br />
        </div>
      )}
      <p>{email}</p>
    </div>
  );
}
