import React from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { shortsCollection } from "../utils/Firebase";
import { nanoid } from "nanoid";
import { addDoc } from "firebase/firestore";
import { Form, Input, Button, Upload as AntUpload, message, Spin, Select, notification} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import TimePicker from 'react-time-picker';
import { set } from "react-ga";
import { useRef } from 'react'; 




export default function Upload(props) {
  const [selectedVideo, setSelectedVideo] = React.useState(null);
  const [uploadMessage, setUploadMessage] = React.useState(null);
  const [thumbnail, setThumbnail] = React.useState("");
  const [hasConnectedAccount, setHasConnectedAccount] = React.useState(null);
  const [accountLink, setAccountLink] = React.useState("");
  const [isRestricted, setIsRestricted] = React.useState(false);
  const [videoDuration, setVideoDuration] = React.useState("");
  const [loading, setLoading] = React.useState(false); // State for loading status
  const { Option } = Select;

  const formRef = useRef(null);


  const [formData, setFormData] = React.useState({
    title: "",
    paywallStart: 10,
    userId: "00:30",
    tags: [],
    price: '$3',

  });

  React.useEffect(() => {
    const checkConnectedAccount = async () => {
      if (!props.id) {
        console.log("User ID is not set, skipping check.");
        return;
      }

      if (!props.userProfile) {
        console.log("userProfile is not set, skipping check.");
        return;
      }


      console.log("props.id: ", props.id);
      console.log("props userProfile: ", JSON.stringify(props.userProfile));
      console.log("props userProfile stripe_account_id: ", JSON.stringify(props.userProfile.stripe_account_id));


      const stripe_acct_id = props.userProfile.stripe_account_id;
  
      try {
        console.log("Checking connected account for user:", props.id, stripe_acct_id);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/check-connected-account`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ accountId: stripe_acct_id }),
        });
  
        const data = await response.json();

        console.log("Request sent to /check-connected-account with creatorId: ", stripe_acct_id);
        console.log("Response from /check-connected-account: ", data);
      

        console.log("Data from check-connected-account: ", data.hasConnectedAccount);
        setHasConnectedAccount(data.hasConnectedAccount);
        setIsRestricted(data.isRestricted);
  
        if (!hasConnectedAccount || isRestricted) {
          console.log("No connected account found, generating account link...");
          // Generate account link if no connected account
          console.log("API call to create-account-link: ", `${process.env.REACT_APP_BACKEND_URL}/create-account-link`);
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-account-link`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ accountId: stripe_acct_id }), // Dummy price to create account
          });
  
          const accountData = await response.json();
          setAccountLink(accountData.url);
        }
      } catch (error) {
        console.error("Error checking connected account: ", error);
      }
    };
  
    checkConnectedAccount();
  }, [props, props.userProfile, props.userProfile.stripe_account_id]); // Dependency array includes props.id



  const handleVideoChange = async (info) => {
    //Structure for plain upload outside of Ant designs
    // const file = event.target.files[0];
    // setSelectedVideo(file);
    // getSelectedVideoThumbnail(file);

    // Ant designs upload
    console.log("File selected info: " + info); // Log the info object to inspect its structure

    if (info.fileList.length === 0) {
      // Handle the case where the file is removed
      setSelectedVideo(null);
      getSelectedVideoThumbnail(null);
      return;
    }
  
    const file = info.fileList[0].originFileObj;

    if (file) {
      const isValid = await validateVideo(file);
      if (isValid) {
        setSelectedVideo(file);
        getSelectedVideoThumbnail(file);
      }

      if (info.file.status === 'removed') {
        console.log('File has been removed');
        setSelectedVideo(null);
      }
    }


    // if (file) {
    //   setSelectedVideo(file);
    //   getSelectedVideoThumbnail(file);
  
    //   if (info.file.status === 'done') {
    //     message.success(`${info.file.name} file uploaded successfully`);
    //     console.log("File uploaded to state sucessfully: ", info.file);
    //   } else if (info.file.status === 'error') {
    //     message.error(`${info.file.name} file upload failed.`);
    //     console.log("File upload to state failed: ", info.file);
    //   }
    // }
  };

  //Old method before new high res image 
  const getSelectedVideoThumbnail = (file) => {
    if (!file) {
      setThumbnail(null);
      return;
    }  

    const video = document.createElement("video");
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    video.preload = "metadata";
    video.src = URL.createObjectURL(file);

    video.onloadeddata = () => {
      // Set the video time to the desired thumbnail time (e.g., 1 second)
      video.currentTime = 0.1;
    };

    video.onseeked = () => {
      // Set canvas dimensions to match video dimensions
      canvas.width = video.videoWidth * 2;
      canvas.height = video.videoHeight * 2;
      // Draw the video frame on the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      // Get the data URL of the canvas
      const dataUrl = canvas.toDataURL("image/png", 1);
      setThumbnail(dataUrl);
    };

    // Seek the video to the desired thumbnail time
    video.currentTime = 0.1;
  };

  function handleFormChange(event) {
    const { name, value, type } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    console.log(JSON.stringify(formData));
  }

  const handleAntFormValuesChange = (changedValues, allValues) => {
    setFormData((prevData) => ({
      ...prevData,
      ...allValues,
    }));
  };

  const sucessfullUploadNotification = () => {
    notification.success({
      message: 'Upload Successful',
      description: 'Your video has been uploaded successfully.',
      placement: 'topRight',
    });
  };
  

  // async function createShort(sid, downloadURL, thumbnailURL) {
  //   const newShort = {
  //     id: sid,
  //     title: formData.title,
  //     paywallStart: formData.paywallStart,
  //     createdAt: Date.now(),
  //     updatedAt: Date.now(),
  //     image: thumbnailURL,
  //     video: downloadURL,
  //     uid: props.userProfile.uid,
  //     stripeAccountId: props.userProfile.stripe_account_id,
  //     tags: ["test", "testerson"],
  //     user: props.id,
  //   };
  //   const newNoteRef = await addDoc(shortsCollection, newShort);
  // }


  async function createShort(sid, downloadURL, thumbnailURL, values) {
    // Perform additional checks
    // Your submit logic here
    const newShort = {
      id: sid,
      title: formData.title,
      paywallStart: formData.paywallStart,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      image: thumbnailURL,
      video: downloadURL,
      uid: props.userProfile.uid,
      stripeAccountId: props.userProfile.stripe_account_id,
      tags: ["test", "testerson"],
      user: props.id,
    };
    const newNoteRef = await addDoc(shortsCollection, newShort);
  };


  function upload() {
    if (!selectedVideo) {
      message.error('Please select a video to upload.');
      return;
    }

    if (formData.paywallStart < 3) {
      message.error('Paywall start time must be longer than 3 seconds.');
      return;
    }

    if (formData.paywallStart > videoDuration * 0.9) {
      message.error(`Paywall start time must be shorter than 90% of the video duration. (${videoDuration * 0.9} for this video)`);
      return;
    }
    uploadVideo();
    // uploadThumbnail();
  }

  const beforeUpload = (file) => {
    return false; // Prevent automatic upload
  };


  const validateVideo = (file) => {
    return new Promise((resolve, reject) => {
      const isVideo = file.type.startsWith('video/');
      if (!isVideo) {
        message.error('You can only upload video files!');
        return resolve(false);
      }

      const isLt30M = file.size / 1024 / 1024 < 100;
      if (!isLt30M) {
        message.error('Video must be smaller than 100MB!');
        return resolve(false);
      }

      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;
        setVideoDuration(duration);
        if (duration > 120) { // 120 seconds = 2 minutes
          message.error('Video must be shorter than 2 minutes!');
          resolve(false);
        } else {
          resolve(true);
        }
      };

      video.onerror = () => {
        message.error('Failed to load video metadata.');
        resolve(false);
      };

      video.src = URL.createObjectURL(file);
    });
  };




  const uploadVideo = async () => {
    console.log("Uploading video...");
    if (!selectedVideo) { // Handle no video selected scenario
      console.log("No video selected.");
      return;
    } 
    console.log ("Selected video: ", selectedVideo);

    const storage = getStorage();
    const sid = nanoid();
    // const storageRef = ref(storage, `shorts/${selectedVideo.name}`);
    const storageRef = ref(storage, `shorts/${sid}`);
    const thumbnailStorageRef = ref(storage, `thumbnails/${sid}`);

    setLoading(true);

    try {
      // You can use the downloadURL to display the uploaded video or store it in a database
      //Upload video
      await uploadBytes(storageRef, selectedVideo);
      const downloadURL = await getDownloadURL(storageRef);
      console.log("Video uploaded successfully!", downloadURL);

      // Convert thumbnail to Blob
      const thumbnailBlob = dataURLToBlob(thumbnail);

      // Upload thumbnail
      await uploadBytes(thumbnailStorageRef, thumbnailBlob);
      const thumbnailURL = await getDownloadURL(thumbnailStorageRef);
      console.log("Thumbnail uploaded successfully!", thumbnailURL);


      // setUploadMessage("Video uploaded successfully!");

      createShort(sid, downloadURL, thumbnailURL);

      sucessfullUploadNotification();

      setThumbnail("");
      setVideoDuration(null);
      setSelectedVideo(null);

      setFormData({
        title: "",
        paywallStart: 10,
        userId: "00:30",
        tags: [],
        price: '$5',
      });


      formRef.current.setFieldsValue({
        title: "",
        paywallStart: 10,
      });
      
  
  
    } catch (error) {
      setUploadMessage("Error uploading video!");
      console.error("Error uploading video:", error);
    } finally {
      setLoading(false);
    }
  };

  const dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  if (props.userProfile.stripe_account_id === null || props.userProfile.stripe_account_id === undefined) {
    return (
      <div>
        <p>There is an issue with your account. Try refreshing the page or contacting support.</p>
        {/* <a href={accountLink} target="_blank" rel="noopener noreferrer">
          Create Connected Account
        </a> */}
      </div>
    );
  }

  if (hasConnectedAccount === null) {
    return <div><Spin tip="Uploading..." /></div>;
  }

  if (!hasConnectedAccount) {
    return (
      <div>
        <p>You need to create a connected account before uploading content.</p>
        <a href={accountLink} target="_blank" rel="noopener noreferrer">
          Create Connected Account
        </a>
      </div>
    );
  }

  if (isRestricted) {
    return (
      <div>
        {/* <p>Your account requires more info, before viewers can pay to view your content.</p> */}
        <p>Your account is missing some details. Complete them to start receiving payments from viewers.</p>
        <a href={accountLink} target="_blank" rel="noopener noreferrer">
          Update Account
        </a>
      </div>
    );
  }

  return (
    <div>      
      <div className="upload--form">
        {/* <label for="title">Title</label>
        old method before ant designs 
        <input
          className="upload--title"
          type="text"
          value="Title"
          placeholder="Title"
          name="title"
          onChange={handleFormChange}
          value={formData.title}
        />
        <br />
        <label for="paywallStart">Paywall Start (Seconds)</label>
        <input
          className="upload--paywallStart"
          type="number"
          placeholder="Paywall"
          name="paywallStart"
          onChange={handleFormChange}
          value={formData.paywallStart}
        />
        <br />
        <input
          className="upload--chooseFile"
          type="file"
          accept="video/*"
          onChange={handleVideoChange}
        />
        <br />
        <button className="upload--uploadButton" onClick={upload}>
          Upload Video
        </button>  */}
        <br />
        

      {/* Ant Design Form */}
      <Form layout="vertical" 
      onFinish={upload}
      onValuesChange={handleAntFormValuesChange}
      initialValues={formData}
      ref={formRef}

      >
      <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: 'Please enter the title' }]}
        >
          <Input
            type="text"
            placeholder="Title"
            // value={formData.title}
          />
        </Form.Item>

        <Form.Item
          label="Paywall"
          name="paywallStart"
          rules={[{ required: true, message: 'Please enter the paywall start time' }]}
        >
          <Input
            type="number"
            placeholder="Paywall"
            value={formData.paywallStart}
            onChange={(e) => setFormData({ ...formData, paywallStart: e.target.value })}

          />

          <Form.Item
            label="Monthly Subscription Price (Currently Fixed)"
            name="price"
            rules={[{ required: true, message: 'Please select a price' }]}
          >
            <Select defaultValue="$5" disabled className="upload--set-price">
              <Option value="$5">$5</Option>
            </Select>
          </Form.Item>


    

        </Form.Item>
        <Form.Item
          label="Upload Video"
          name="videoFile"
          rules={[{ required: true, message: 'Please upload a video file' }]}
        >
          <AntUpload
            // className="upload--uploadButton"
            accept="video/*"
            // beforeUpload={() => false} // Prevent automatic upload
            beforeUpload={beforeUpload} // Add the beforeUpload prop
            onChange={handleVideoChange}
            maxCount={1} // Limit to one file
          >
            <Button 
              className="upload--button"
              icon={<UploadOutlined />}>Choose File</Button>
          </AntUpload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Upload Video
          </Button>
        </Form.Item>
      </Form>
    {loading && <Spin tip="Uploading..." />} {/* Display loading spinner */}
    {/* {<p>has connected account: {hasConnectedAccount.toString()}</p>}
    {<p>has connected account: {props.userProfile.stripe_account_id}</p>}
    {isRestricted} */}
    {uploadMessage && <p>{uploadMessage}</p>}
    {/* {JSON.stringify(formData)} */}
    {/* {props.userProfile.stripe_account_id} */}
    <br />
    {thumbnail && (
    <div>
      <img src={thumbnail} className="upload--thumbnail" alt="Video Thumbnail" />
      <p>Extracted Thumbnail</p>
    </div>
)}    <br />
    <div>Video Length: {videoDuration}</div>


      </div>
    </div>
  );
}
