import React from "react";
// import Short from "./components/Short";
import { onSnapshot, addDoc, doc, deleteDoc, setDoc, limit, query, orderBy } from "firebase/firestore";
import { db, shortsCollection } from "../utils/Firebase";
import { useNavigate } from "react-router-dom";
import ShortThumbnail from "./ShortThumbnail";

export default function Body() {
  const [shorts, setShorts] = React.useState([]);
  const navigate = useNavigate();


  React.useEffect(() => {
    const q = query(shortsCollection, orderBy('createdAt', 'desc'), limit(100));
    const unsubscribe = onSnapshot(q, function (snapshot) {
      const shortsArray = snapshot.docs.map((doc) => ({
        ...doc.data(), //,
        // id: doc.id,
      }));
      setShorts(shortsArray);
    });
    return unsubscribe;
  }, []);

  // React.useEffect(() => {
  //   const unsubscribe = onSnapshot(shortsCollection, function (snapshot) {
  //     const shortsArray = snapshot.docs.map((doc) => ({
  //       ...doc.data(), //,
  //       // id: doc.id,
  //     }));
  //     setShorts(shortsArray);
  //   });
  //   return unsubscribe;
  // }, []);


  
  // const handleClick = () => {
  //   navigate("/profile");
  //   console.log("Body > handleClick"); 
  // };
  

  const mapped_shorts = shorts.map((i) => {
    return (
      <ShortThumbnail
        // onClick={() => handleClick()}
        image={i.image}
        title={i.title}
        video={i.video}
        id={i.id}
        key={i.id}
      />
    );
  });

  return <div className="app--body">{mapped_shorts}</div>;
}
