import React from "react";
import { useParams } from "react-router-dom";
import Short from "./Short";
import { onSnapshot, query, where, addDoc, doc } from "firebase/firestore";
import { db, shortsCollection } from "../utils/Firebase";
// import { onSnapshot, query, where } from 'firebase/firestore';

export default function ShortViewer() {
  const { id } = useParams();
  const [shortValue, setShortValue] = React.useState([]);

  React.useEffect(() => {
    if (!id) {
      console.error("ID is not defined");
      return;
    } else {
      console.log("Running API call for ID:" + id);
    }

    const q = query(shortsCollection, where("id", "==", id));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const db_value = snapshot.docs.map((doc) => ({
        ...doc.data(), //,
      }));
      setShortValue(db_value);
    });

    console.log("ShortValue: ");
    console.log(JSON.stringify(shortValue));
    return unsubscribe;
  }, []);

  return (
    <div className="short--viewer">
      {/* If a video with the ID in the URL is found, show it.  */}
      {shortValue.length > 0 && shortValue[0].id != null ? (
        <div>
          <Short
            image={shortValue[0].image}
            title={shortValue[0].title}
            video={shortValue[0].video}
            id={shortValue[0].id}
            key={shortValue[0].id}
            paywallStart={shortValue[0].paywallStart}
            uid={shortValue[0].uid}
            stripe_account_id={shortValue[0].stripeAccountId}
            email={shortValue[0].email}
          />
        </div>
      ) : (
        <h1>Broken link</h1>
      )}
    </div>
  );
}
